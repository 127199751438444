@import url('https://fonts.googleapis.com/css2?family=Parisienne&display=swap');
@import 'photoviewer/dist/photoviewer.css';
body {
    margin: 0;
    background-color: #000033;
    overflow-x: hidden;
}

.jumbotron {
    font-family: 'Parisienne';
    font-size: 72px;
    color: white;
    display: inline-flex;
    width: 100%;
    margin: 10% auto 0 !important;
    justify-content: center;
}

.jumbotron p {
    padding: 50px 50px 0;
}

.jumbotron img {
    border-radius: 10%;
}

@media screen and (max-width: 1100px) {
    .jumbotron p {
        font-size: 54px;
    }
}

@media screen and (max-width: 700px) {
    .jumbotron {
        width: 80%;
        margin-left: 10% !important;
    }
    .jumbotron p {
        padding: 0;
        margin: 10px !important;
        text-align: center;
    }
}

@media screen and (max-width: 500px) {
    .jumbotron {
        width: 80%;
        margin-left: 10% !important;
    }
    .jumbotron p {
        padding: 0;
        margin: 30px 10px 0 !important;
        text-align: center;
        font-size: 30px;
    }
}

/*nav*/

nav {
    margin: 30px 0 50px;
    text-align: center;
    display: flex;
    justify-content: space-around;
}

nav a {
    color: white;
    text-decoration: none;
}

nav a .silver {
    background-image: url('./img/home_nav/silver.gif');
    width: 61px;
    height: 31px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#silver :hover {
    transition: 0.4s;
    background: url('./img/home_nav/silver_after.gif');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

nav a .keramika {
    background-image: url('./img/home_nav/keramika.gif');
    width: 61px;
    height: 36px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#keramika :hover {
    transition: 0.4s;
    background: url('./img/home_nav/keramika_after.gif');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

nav a .grafika {
    background-image: url('./img/home_nav/grafika.gif');
    width: 61px;
    height: 36px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#grafika :hover {
    transition: 0.4s;
    background: url('./img/home_nav/grafika_after.gif');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

nav a .contact {
    background-image: url('./img/home_nav/contact.gif');
    width: 61px;
    height: 36px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#contact :hover {
    transition: 0.5s;
    background: url('./img/home_nav/contact_after.gif');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

nav a .logo {
    background-image: url('./img/logo.gif');
    width: 65px;
    height: 28px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.photo-caption {
    position: fixed;
    bottom: 20px;
    width: 100%;
    text-align: center;
    font-size: 42px;
    color: white;
    z-index: 10;
    padding: 20px;
}

.gallery {
    text-align: center;
    justify-content: center;
    align-items: center;
}

.gallery-wrap {
    /* display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 5%;
    width: 100%; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.gallery-wrap .img-cont {
    flex: 0 0 33%;
    margin-bottom: 50px;
    align-self: center;
}

.img-cont img {
    margin: auto 20px !important;
}

.gallery-wrap img {
    cursor: pointer;
}

.silver .img0, .silver .img1, .silver .img2 {
    height: 500px !important;
}

.silver .img3, .silver .img4, .silver .img5 {
    height: 250px !important;
}

.silver .img7, .silver .img6 {
    height: 400px;
    margin-top: 20px !important;
}

.silver .img9 {
    height: 250px;
}

.silver .img8 {
    height: 300px;
}

.react-images__header_button--fullscreen, .react-images__footer__count {
    display: none !important;
}

.react-images__footer__caption {
    width: 100%;
    text-align: center;
    font-size: 30px !important;
}

.graphics img {
    max-width: 80vw;
    max-height: 80vh;
}

.input-form {
    display: flex;
    flex-direction: column;
    margin: 0 30vw 20px;
}

.input-form label {
    text-align: center;
    font-size: 20px;
    color: white;
}

.input-form input {
    margin-top: 20px;
}

textarea {
    width: 100%;
    height: 150px;
    margin-top: 20px !important;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    resize: vertical;
}

input[type=email] {
    padding: 12px 20px;
    margin: 8px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

button[type=submit] {
    align-self: center;
    min-width: 40%;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button[type=submit]:hover {
    background-color: #45a049;
}

@media screen and (max-width: 1000px) {
    .input-form {
        margin: 0 15vw 20px;
    }
}

.error {
    color: red !important;
}

.input-form .errors {
    text-align: center;
    margin-top: 10px;
    color: red;
    font-size: 18px;
}

button:disabled:hover, button:disabled {
    background-color: gray;
    cursor: initial;
}