@import url(https://fonts.googleapis.com/css2?family=Parisienne&display=swap);
body {
    margin: 0;
    background-color: #000033;
    overflow-x: hidden;
}

.jumbotron {
    font-family: 'Parisienne';
    font-size: 72px;
    color: white;
    display: -webkit-inline-flex;
    display: inline-flex;
    width: 100%;
    margin: 10% auto 0 !important;
    -webkit-justify-content: center;
            justify-content: center;
}

.jumbotron p {
    padding: 50px 50px 0;
}

.jumbotron img {
    border-radius: 10%;
}

@media screen and (max-width: 1100px) {
    .jumbotron p {
        font-size: 54px;
    }
}

@media screen and (max-width: 700px) {
    .jumbotron {
        width: 80%;
        margin-left: 10% !important;
    }
    .jumbotron p {
        padding: 0;
        margin: 10px !important;
        text-align: center;
    }
}

@media screen and (max-width: 500px) {
    .jumbotron {
        width: 80%;
        margin-left: 10% !important;
    }
    .jumbotron p {
        padding: 0;
        margin: 30px 10px 0 !important;
        text-align: center;
        font-size: 30px;
    }
}

/*nav*/

nav {
    margin: 30px 0 50px;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
}

nav a {
    color: white;
    text-decoration: none;
}

nav a .silver {
    background-image: url(data:image/gif;base64,R0lGODlhPQAkAKIAAABm/wBmzAAzzAAzmQAzZgAAZgAAMwAAACwAAAAAPQAkAEAD/2i63P4wyrkCAOOVy7sPYAh6XEZBA+dsF3Gml7DA8ikRV/AMFhkIgwKKI7QZj8ikcsnEXYoMJ8ClEQ2oDhXTANM1OF5IgVeqXExbRWfAFvRIcB82Ta8/tKhRfP9BI1lTd2d0eEgwAA9vEAJxfixhSocOXUZ4PX5LHHNcfHFzl3WgNgWkBGwDbiSYaYc+bKRQdrKztLW2t6NLsKWnvVexSkQQb53FOZGFC4BBu0gEjBdKPZAL0NTB0dIxghjDcKvJR2UNh8DKp8RYlEzjDO0T6wai7INRwhTx80tSDm8BQaZ4EOvAQN8SGKsG7hGwyYCmOvc0wKJAgJidcg5M6TEGZhNWK44+/jWcNSYEkJG4UqpcSSEBADs=);
    width: 61px;
    height: 31px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#silver :hover {
    transition: 0.4s;
    background: url(data:image/gif;base64,R0lGODlhPQAkAKIAAABm/wBmzAAzzAAzmQAzZgAAZgAAMwAAACH5BAAAAAAALAAAAAA9ACQAQAP8aLrc/jDKSR0Y9ZGwgwkeNVxZwZCkUgAKEI5Y1hQBYN8C4YymMcrACmhArKWCyKRyCSwUAwOdJRZZcWiA3pGyzfwMK+qIxWSupOW0es1WEojtRldSMAk8LgW1snGhVWJSc2AsIEYDTgtZXmRKJAQ2b40+e0g8D05GNmJkYXFMIJ9sNT2ipqeoqaqrrGsraZCbol9BKzcmg2q0ErE2Aia7QQNGhjc7kww0PixOkXrIIi6lEFbPmCxjMcElAAJKPyMhkAq9uVyVFSk1C5ptAd4TdXIx6wuJbc6ARgKIfVTq8FC9ITJtCrtWQKAgRBJqISMAfxwyGHZDocSLQBIAADs=);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

nav a .keramika {
    background-image: url(data:image/gif;base64,R0lGODlhPQAkALMAAP/MAP+ZAMyZAJmZAJlmAGZmM2YzMzMzMzMAMwAAMwAAAAAAAAAAAAAAAAAAAAAAACwAAAAAPQAkAEAE/zDJSau9OOttC/hgKAZCAArHQRmgwb2VURCCaN94KMB8EqqSQy0nck2GhB5sCAgAldAoJiWtUg6EE9EmoIl21omHKCgXhoLCGaRGT1phywFEOmFYoGTFE0DE/4BhWQBggRsHMm0fdVtbhX9MjVwUY2WLf0KSmjpDA4FYm1tAeACGpDldBQYpNhVzH09/gyGeGXiPhhlUuby9vr++YwXAFQgDZchmqrsSTEYUCCZ6gGOhRCaLfr1jARIIBjSRms9xBuKbKGKLbICz1l9o1eRhCAcD75LzUNX4OOnV5Qhg62cDyD0Atf4g+JFojaZHcAAdBEDgzEBNsbgZOhexQiRcCRywDQuEzcsHfUdAxAL2ys4GNSuJvRKgjZjNMBEAADs=);
    width: 61px;
    height: 36px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#keramika :hover {
    transition: 0.4s;
    background: url(data:image/gif;base64,R0lGODlhPQAkALMAAP/MAP+ZAMyZAJmZAJlmAGZmM2YzMzMzMzMAMwAAMwAAAAAAAAAAAAAAAAAAAAAAACH5BAAAAAAALAAAAAA9ACQAQAT/MMlJq704C5K7/+BFBNKIJIAgDIdXAGcoFQHAEYBkAC1aJL3PrhfgWAAFgwBASoxk0E4xSq1ar5QDwIDtYnCpxDAhCJwGgsJggkBaVB50effitnGtn9eZ2hSSBwcxBmh6e4eIFW2GiYdqYjBvAUmNVAFccilrQDSbHWNWBDgDYWQcLycCPQeMHU8ggkA2ZFtkAjOeUFpcE3AWIwCkBi8tLWVLvInEbhcBAcXMlR6+WYF/BQS50h6L297br99eaOJ71BQIpEblFwNNUxJpu+whi1p6hGXa7Kzxt1oAUrh5wQKWlwIrkIzZAOkEwHUfAtyi8qPNLF+oEly6ggCeDIJlW2TxgmcgxocDTSrcA7GEBzlat0CFmjhB5oUyNQrsquUuwYBkXagRs6COxkSPTgpWmhIuC5MUBghU5MGujcQMO1SUojdBiwqtAcMyUQGRqw6qZinmSEslG9sOEQAAOw==);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

nav a .grafika {
    background-image: url(data:image/gif;base64,R0lGODlhPQAkAKIAAP8AAMwAAJkAAGYAM2YAADMAMwAAMwAAACH5BAAAAAAALAAAAAA9ACQAQAPwaLrc/jDKSQ24gYhw76hg2AhYUXRoqqaFCHLeMsDA3H0LDQim6f7AoHBIBJE6gZYlhTMMjihBkXG6KDtQ220BRU0fBR1GUV01v+j0TwwInNXrjtJQFhDe8El3xRzw22pHUgZiTQRbNFoBanUKe3xninlLGANaNYcXXChsAJMTBRsZc5+lpqeoqRRPAQECeKoQjx0EsRJbCn5ytg+6MQqZWBGkk2VYYSoKnZpwy39/A8RTZY6cPSbPwmq7lEjA3k6cHYy42dwGwReDaGWLytnuuWOT6QwwSbh02eR8i8ECe6SESjRJ0Zl06qTxWsiw4ZcEADs=);
    width: 61px;
    height: 36px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#grafika :hover {
    transition: 0.4s;
    background: url(data:image/gif;base64,R0lGODlhPQAkAKIAAP8AAMwAAJkAAGYAM2YAADMAMwAAMwAAACH5BAAAAAAALAAAAAA9ACQAQAP/aLrc/jDKSSsLwRVTwICFIGxNEQDBQBYEaSlbB3gMIHC0gREZ07mUwoCA+8BmqEfg9noQZkbGM9esWq+PgYoh6GG/kAG10cURDQKkVxytpmkYxanH/oqBupmgfQf7/xU/gIAYBSZeOkyDYDYMA0x9Cns6iBGGWnhWaRxpjz1ISDAYAC4dlVhiowuNCx0fQq2ZBqwvJzMZOwAKdzmbWGMOj7cDSwpPixNiihFpssiBnktbz9TV1ou01xYyMy512hYBZ4ZcuuAVkZKklFkoAc7PZRCszbPL54lkH2yFKyzrwWyBalPFlB4h69KIq2NiRINU8F6YaDQEoDo5kPQoMXeFUJwBZXmMyFswycLIFyKUdQKIhES2KsAm/Ki3506HG9NcdbQogdgtjD4IEFzwCEsxDV0OKshhIiK2SlqUnMKH5mWDE0OptrKFYRQKp1rDvkgAADs=);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

nav a .contact {
    background-image: url(data:image/gif;base64,R0lGODlhPQAkAOYAAMgc+Mwd/L8b77oa67oa6rka6bIZ4rAZ4MYc9sUc9cQc9MIc88Mc88Ic8r4b7r0b7bwb7KwY3J4WzqgY2aYY16YY1qIX0p8X0JAUwY4Uv5oWy5kWypcVx5UVxpYVxpMVxJ4Xz5gWyX0RrokTuoQTtYwUvYoUu3MQpXoRq3IQo3YRp3URpmYOl2wPnWENkl4NkF8NkF4Nj10NjmMOlWIOk2AOkVYMh1wNjloNi1ELg1ALgk8LgVQMhkkKe0oLfEYKeEQKdjoIbDkIazQHZjIHZTMHZTIHZDEHYy4HYSYFWSIFVSIFVCAFUyEFUxoETRYDSRMDRhIDRA4CQQ0CQA4CQAoBPQoBPAwCPwkBPAgBOwcBOgYBOQUBOAQBNwIANQEANAAAMwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACwAAAAAPQAkAAAH/4BggoOEhYaHiImKi4yNjo+QkZKTlJWWl5iZijGanYYxnJ6iYKCjnqChppilqaSNXl6qhaGtrYdfUUNKWl+ypLSDpYowIhMqTrGqwq6/y4cwJQghTcmmtYLAiDEsGwkoVL3WtajY2jU4BQc9W8q2zrbYMU8mARnIo+SEzsz6MV5CFB7QwILP3T5+8cBwsVEgQg4pWbZUq4TKoLZ+grbwKGCgw4oZMoxYoVTx08FgwVJ5IRJigAIGDRZkYDKRUclZ+RLRsjUlSAsNBAAEsLAk3KEYGEBhSAovp81fhLxsUfLCg4MAAi70qGL06M6KNx859QLFR4WrED7cUMLOJrxGJ2WSmDR0ZQeBAA/U7uq66OQgIBIMkThiCIOhL1A4OLAwY68kp4SAgCh0JcCUQks/uUjxA8mVmpEgI9IxojBOTl66gCYpWhCRAAGK4IzntxPYtynB+tJ5uzfu3cCDCx9OvLjx47ICAQA7);
    width: 61px;
    height: 36px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#contact :hover {
    transition: 0.5s;
    background: url(data:image/gif;base64,R0lGODlhQwAqAMQAAMDAzbQ78hgHTDAPZgwDP3gnsiQLWZwz2FQbjGAfmTwTcpAvzEgXf6g35YQrv2wjpcA//wAAM////wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAABIALAAAAABDACoAAAX/YCSOZGmeaKqubOu+cCzPdG3feK7vfO//wKBwmEMIiL3Aw2QYEJCzwqFUgEAQUJkCIhAMIttvViaANMwRRMAwfg0GCWtAIRIErI1nG8W4QxYDW0cjgRBiQQIFKQZsAxAFgyIBWAMLEQsBAZFAjpt1C1YCD1NUDQMHDWlGLQQKCa+rMJMlBgENcQgODiMECQR2ZmwuBA93BwsHVlgvUgQIdBEHBwSODQyGEQRVni61k3rZTi/EcgwR10fXEAZVctAxtdM0jJcQAYoiBbuXDg1LBgkUgIvRQF6MLqAgPBCgjVQEKWkMIUiAA90MUAEGIOAChqODO3co6jiAr4WCBQt8iG00F2FWSwe4AArTAeHdijgFih0oAw0imExLfDgaqCKASAM1dYlAOjOIoxfYRBxIcE1PAm5CObYwurQmgTVtILBkgVPnnhEOLLFIh/IB0TGODqWYehbFx6Ymqsity+tAxhPaxPJFQQBUgUMCEDQIMHbwCQbJzMiB5HitRgV7K2vezLmz5885QgAAOw==);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

nav a .logo {
    background-image: url(data:image/gif;base64,R0lGODlhQQAcAMQQAO8AAw8AME8AI98ABh8ALT8AJq8AEL8ADW8AHS8AKp8AE48AFs8ACl8AIH8AGv8AAAAAMwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAABAALAAAAABBABwAAAX/ICSOZGmeqOg87KMEaSzPtEi0rVPv/FzgLEBvOBIYEInaCshqEHvLoEAmALQAhtZg+qwhgIhY4LoNRLndWGHAXKRuuGnCGlSk34oWgy48EegsBiMLB3p3JwFZLQcFhQ9hJgt6BSQBC1gsSYckeSwDAwsBbA8HmiM/QZQlAQeOgpsiki0FkAwtryM4bidzqbCihiSKAwQjCS0vKUaeToeoDwDFJM8HRS3SKVEPqmmA2CPALKpmWDMJsixoRA04AAcDjKfIJaYyX9dP7ExaoAgHeQBgDAnQ6QEDgTwE7Fto0AqkIQIONLASbweBUUAAaAQCj06zIQkS0VEnA50VBw0KiiT4wmAAAwUCEsxRxG2IAkUA6qV4ZnAXuAABvukz2CUAggaOZlSJs8MoGwMIhxC4mS7FPU8klV568I0IgZYDdIpAwMcOkQAKXThj8xBCAFu3ag4xcABA1iENBgBoRkBRC7FSsziI6jVPA1Y4HHTtsubRIQICBKB78HFT0CwMYEHwtBhWFQQLOvcIAQA7);
    width: 65px;
    height: 28px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.photo-caption {
    position: fixed;
    bottom: 20px;
    width: 100%;
    text-align: center;
    font-size: 42px;
    color: white;
    z-index: 10;
    padding: 20px;
}

.gallery {
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.gallery-wrap {
    /* display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 5%;
    width: 100%; */
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
}

.gallery-wrap .img-cont {
    -webkit-flex: 0 0 33%;
            flex: 0 0 33%;
    margin-bottom: 50px;
    -webkit-align-self: center;
            align-self: center;
}

.img-cont img {
    margin: auto 20px !important;
}

.gallery-wrap img {
    cursor: pointer;
}

.silver .img0, .silver .img1, .silver .img2 {
    height: 500px !important;
}

.silver .img3, .silver .img4, .silver .img5 {
    height: 250px !important;
}

.silver .img7, .silver .img6 {
    height: 400px;
    margin-top: 20px !important;
}

.silver .img9 {
    height: 250px;
}

.silver .img8 {
    height: 300px;
}

.react-images__header_button--fullscreen, .react-images__footer__count {
    display: none !important;
}

.react-images__footer__caption {
    width: 100%;
    text-align: center;
    font-size: 30px !important;
}

.graphics img {
    max-width: 80vw;
    max-height: 80vh;
}

.input-form {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: 0 30vw 20px;
}

.input-form label {
    text-align: center;
    font-size: 20px;
    color: white;
}

.input-form input {
    margin-top: 20px;
}

textarea {
    width: 100%;
    height: 150px;
    margin-top: 20px !important;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    resize: vertical;
}

input[type=email] {
    padding: 12px 20px;
    margin: 8px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

button[type=submit] {
    -webkit-align-self: center;
            align-self: center;
    min-width: 40%;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button[type=submit]:hover {
    background-color: #45a049;
}

@media screen and (max-width: 1000px) {
    .input-form {
        margin: 0 15vw 20px;
    }
}

.error {
    color: red !important;
}

.input-form .errors {
    text-align: center;
    margin-top: 10px;
    color: red;
    font-size: 18px;
}

button:disabled:hover, button:disabled {
    background-color: gray;
    cursor: auto;
    cursor: initial;
}
